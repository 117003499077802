<!--
 * @Description: 实时预览弹窗
 * @Autor: WangYuan
 * @Date: 2021-07-05 17:05:21
 * @LastEditors: WangYuan
 * @LastEditTime: 2022-02-11 10:27:44
-->
<template>
  <div class="RealTimeView">
    <el-dialog
      v-if="mShow"
      :append-to-body="true"
      :visible.sync="mShow"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="415px"
      top="8vh"
    >
      <phone-ctn :head="false">

        <iframe
          id="cover"
          v-if="mShow"
          ref="iframe"
          class="screen"
          :scrolling="false"
          :src="iframeSrc"
          @load="load"
        ></iframe>

      </phone-ctn>
    </el-dialog>
  </div>
</template>

<script>
import global from "@/config/global";
import { mapGetters } from "vuex";

export default {
  name: "RealTimeView",

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    previewRow: {
      type: Object,
      default: () => {},
    }
  },

  data() {
    return {
      mShow: false,
    };
  },

  computed: {
    ...mapGetters(["project", "curPage"]),

    iframeSrc() {
      console.log("this.project:",this.project);
      console.log("this.curPage:",this.curPage);

    // return ;

      if (this.$route.path == '/mall/page-build') {

        if (this.curPage.sign == 'home') return `${global.viewUrl}pages/index/tabbar/home?operate=realTime`;
        else if (this.curPage.sign == 'user') return `${global.viewUrl}pages/index/tabbar/tab-third?operate=realTime`;
        else return `${global.viewUrl}pages/index/custom/custom?operate=realTime&pageId=${this.curPage.pagesId}`;

        // if (this.curPage.home == 0)
      } else if (this.$route.path == '/mall/pages-manage' && this.previewRow) {

        if (this.previewRow.sign == 'home') return `${global.viewUrl}pages/index/tabbar/home?operate=realTime`;
        else if (this.previewRow.home == 'user') return `${global.viewUrl}pages/index/tabbar/tab-third?operate=realTime`;
        else return `${global.viewUrl}pages/index/custom/custom?operate=realTime&pageId=${this.previewRow.pagesId}`;

        // if (this.previewRow.home == 0)
      } else {

        return `${global.viewUrl}pages/index/tabbar/home?operate=realTime`;

      }



    },
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue, oldValue) {
        this.mShow = newValue;
      },
    },
    mShow: {
      immediate: true,
      handler(newValue, oldValue) {
        this.$emit("update:show", newValue);
      },
    },
  },

  methods: {
    load() {
      this.$nextTick(() => {
        this.$refs["iframe"] &&
          this.$refs["iframe"].contentWindow.postMessage(this.project, "*");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ctn {
  margin: 0;

  .ctn-height-tag {
    display: none;
  }
}

.screen {
  width: 375px /*no*/;
  height: 667px /*no*/;
  border: 0;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}

::v-deep .PhoneCtn {
  width: 414px;
  margin: 0;

  .preview-height-tag {
    display: none;
  }

  .preview-body {
    height: 667px;
    // 隐藏滚动条
  }
}
</style>